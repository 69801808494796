<template>
  <tbody>
  <tr class="text-center">
    <td>{{ $t('labels.cal_payroll') }}</td>
    <td>
      <SelectMultiplePos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_poss" :default-value="['all']" @onFilter="onFilterChange"/>
    </td>
    <td>
      <SelectMultipleEmployeeByPos :disabled="true" :label="$t('labels.employee')" :placeholder="$t('labels.employee')" name="id_employees" :default-value="['all']" :id-warehouses="filters.id_poss" @onFilter="onFilterChange" />
    </td>
    <td>
      <DateRangeFilter :disabled="isDisabledDateRange" :min-date="minDate"
                       :label="$t('labels.from_to')" :placeholder="$t('labels.from_to')"
                       name="dates" :default-value="filters.dates" @onFilter="onFilterChange" />
    </td>
    <td>
      <v-btn color="success" :disabled="isDisabledAction" @click="exportExcel"><v-icon>mdi-download</v-icon></v-btn>
    </td>
    <td></td>
  </tr>
  </tbody>
</template>

<script>

import moment from "moment";

export default {
  name: "Payroll",
  components: {
    SelectMultiplePos: () => import('@/components/administration/SelectMultiplePos'),
    SelectMultipleEmployeeByPos: () => import('@/components/administration/SelectMultipleEmployeeByPos'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
  },
  data: () => ({
    filters: {
      id_poss: [],
      id_employees: [],
      dates: [],
    },
    isLoading: false,
    minDate: null,
  }),
  computed: {
    isDisabledDateRange() {
      return !this.filters.id_employees || this.filters.id_employees.length === 0
    },
    isDisabledAction() {
      return this.isDisabledDateRange || !this.filters.dates || this.filters.dates.length === 0
    }
  },
  created() {
    let startOfSubMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    let endOfSubMonth = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    const {startDate, endDate, minDate} = this.defaultDataDate({
      startDate: startOfSubMonth,
      endDate: endOfSubMonth,
      warehouses: window.me && window.me.warehouses || []
    })
    this.minDate = minDate
    this.filters = {...this.filters, dates: [startDate, endDate]}
  },
  methods: {
    onFilterChange(filter) {
      this.filters = {...this.filters, [filter.name]: filter.value}
    },
    async exportExcel() {
      this.$emit('exportExcel', {params: this.filters, type: 'pos_payroll'})
    },
  }
}
</script>

<style scoped>

</style>
